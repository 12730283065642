/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default [
  {
    url: null,
    name: 'Solicitud',
    slug: 'solicitud',
    icon: 'NavigationIcon',
    submenu: [
      {
        url: '/solicitud',
        name: 'Solicitar servicio',
        slug: 'solicitud',
      },
      {
        url: '/panel-de-control',
        name: 'Panel de control',
        slug: 'panel-de-control',
      },
      {
        url: '/historial-de-servicios',
        name: 'Historial de servicios',
        slug: 'historial-de-servicios',
      },
    ],
  },
  {
    url: null,
    name: 'Socios',
    slug: 'socios',
    icon: 'UsersIcon',
    submenu: [
      {
        url: '/socios/logisticos',
        name: 'Socios Logisticos',
        slug: 'socios-logisticos',
      },
      {
        url: '/socios/saldo-socios',
        name: 'Saldo socios',
        slug: 'saldo-socios',
      },
      {
        url: '/socios/puntos-socios',
        name: 'Puntos socios',
        slug: 'puntos-socios',
      },
      {
        url: '/socios/saldo-en-canje',
        name: 'Saldo en canje',
        slug: 'saldo-en-canje',
        meta: {
          permission: 'show_exchange_balance',
        },
      },
      {
        url: '/mapa',
        name: 'Mapa',
        slug: 'Mapa',
      },
      {
        url: '/socios/cuentas-bancarias',
        name: 'Cuentas bancarias',
        slug: 'socios-cuentas-bancarias',
        meta: {
          permission: 'bank_accounts_administrator',
        },
      },
    ],
  },

  {
    url: null,
    name: 'Configuración',
    slug: 'configuracion',
    icon: 'SettingsIcon',
    submenu: [
      {
        url: '/configuracion/empresa',
        name: 'Empresa',
        slug: 'configuracion-empresa',
        meta: {
          permission: 'show_set_up_company',
        },
      },

      {
        url: '/configuracion/alarmas',
        name: 'Alarmas',
        slug: 'configuracion-alarmas',
      },
      {
        url: '/configuracion/tarifas-por-area',
        name: 'Tarifa por áreas',
        slug: 'configuracion-tarifas-por-area',
        meta: {
          permission: 'edit_area_tariffs',
        },
      },
      {
        url: '/configuracion/negociaciones',
        name: 'Negociaciones',
        slug: 'configuracion-negociaciones',
        meta: {
          permission: 'edit_negotiations',
        },
      },
      /* Se elimino el componente por que no se tiene previsto.
      {
        url: '/configuracion/capacitaciones',
        name: 'Capacitaciones',
        slug: 'configuracion-capacitaciones',
      },
      */
    ],
  },
  {
    url: null,
    name: 'Inventario',
    slug: 'inventario',
    icon: 'ShoppingBagIcon',
    permission: 'show_inventory',
    submenu: [
      {
        url: '/inventario/productos',
        name: 'Productos',
        slug: 'inventario-productos',
        meta: {
          permission: 'show_inventory',
        },
      },
      {
        url: '/inventario/historial',
        name: 'Historial de inventario',
        slug: 'inventario-historial',
        meta: {
          permission: 'show_inventory',
        },
      },
      {
        url: '/inventario/ventas',
        name: 'Ventas de inventario',
        slug: 'inventario-ventas',
        meta: {
          permission: 'show_inventory',
        },
      },
    ],
  },
  {
    url: null,
    name: 'Finanzas',
    slug: 'finanzas',
    icon: 'PercentIcon',
    submenu: [
      {
        url: '/finanzas/dashboard',
        name: 'Dashboard',
        slug: 'finanzas-dashboard',
      },
      {
        url: '/finanzas/cajas',
        name: 'Cajas',
        slug: 'finanzas-cajas',
        meta: {
          permission: 'show_accounts',
        },
      },
      {
        url: '/finanzas/facturacion',
        name: 'Facturación',
        slug: 'financias-facturacion',
        meta: {
          permission: 'show_invoices',
        },
      },
      {
        url: '/finanzas/solicitudes-retiro',
        name: 'Solicitudes de retiro',
        slug: 'finanzas-solicitudes-retiro',
        meta: {
          permission: 'withdraw_request_administrator',
        },
      },
      {
        url: '/finanzas/historial-solicitudes-retiro',
        name: 'Historial Solicitudes de retiro',
        slug: 'finanzas-historial-solicitudes-retiro',
        meta: {
          permission: 'withdraw_request_administrator',
        },
      },
    ],
  },
  {
    url: null,
    name: 'Calendario',
    slug: 'calendario',
    icon: 'CalendarIcon',
    submenu: [
      {
        url: '/calendario',
        name: 'Calendario',
        slug: 'calendario',
      },
    ],
  },

  {
    url: null,
    name: 'Terceros',
    slug: 'terceros',
    icon: 'BriefcaseIcon',
    submenu: [
      {
        url: '/terceros/franquicias',
        name: 'Franquicias',
        slug: 'terceros-franquicias',
        meta: {
          permission: 'edit_franchises',
        },
      },
      {
        url: '/terceros/admin-franquicias',
        name: 'Admin franquicias',
        slug: 'terceros-admin-franquicias',
        meta: {
          permission: 'show_admin_franchises',
        },
      },
      {
        url: '/terceros/barrios',
        name: 'Barrios',
        slug: 'terceros-barrios',
      },
    ],
  },

  {
    url: null,
    name: 'Plantas',
    slug: 'plantas',
    icon: 'MapPinIcon',
    submenu: [
      {
        url: '/plantas',
        name: 'Listado de plantas',
        slug: 'plantas',
        meta: {
          permission: 'show_plants',
        },
      },
      {
        url: '/plantas/delegacion',
        name: 'Delegaciones',
        slug: 'delegacion-plantas',
      },
      {
        url: '/plantas/admin',
        name: 'Administración',
        slug: 'plantas-admin',
        meta: {
          permission: 'show_admin_plants',
        },
      },
    ],
  },
];
