<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.first_name"
  >
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">
        {{ activeUserInfo.first_name + ' ' + activeUserInfo.last_name }}
      </p>
      <!--  <small>{{ $laravel.getRoles() }}</small> -->
      <small v-if="$gates.getRoles()">{{ $gates.getRoles()[0] | roles }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          v-if="activeUserInfo.photoURL"
          key="onlineImg"
          :src="activeUserInfo.photoURL"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 12rem">
          <!-- <vs-divider class="m-1" /> -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Cerrar sesión</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    // logout() {
    //   this.$router.push('/iniciar-sesion').catch(() => {});
    // },
    logout() {
      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken');
        this.$router.push('/iniciar-sesion').catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      // this.$acl.change('public');
      localStorage.removeItem('userInfo');
      this.$gates.setRoles([]);
      this.$gates.setPermissions([]);
      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/iniciar-sesion').catch(() => {});
    },
  },
};
</script>
