<template>
  <div id="company-select">
    <vs-select class="" placeholder="Compañias" v-model="selectedCompany">
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.name"
        v-for="(item, index) in companies"
      />
    </vs-select>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { COMPANIES } from '@/graphql/queries';
import { UPDATE_USER_COMPANY } from '@/graphql/mutations';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';

export default defineComponent({
  setup(_, { root }) {
    const selectedCompany = ref();
    const { result: companiesResult } = useQuery(COMPANIES);
    const companies = useResult(companiesResult, [], (data) => data.companies);

    const activeUserInfo = computed(() => root.$store.state.AppActiveUser);

    const { mutate: updateUserCompany, onDone: onUpdateUserCompanyDone } =
      useMutation(UPDATE_USER_COMPANY);

    selectedCompany.value = activeUserInfo.value.company_id;
    console.log(
      '🚀 ~ file: CompanySelect.vue ~ line 26 ~ setup ~ selectedCompany.value',
      selectedCompany.value
    );

    onUpdateUserCompanyDone(({ data }) => {
      console.log(
        '🚀 ~ file: CompanySelect.vue ~ line 32 ~ onResult ~ data',
        data
      );
      console.log(selectedCompany.value);
      root.$store.dispatch('updateUserInfo', {
        company_id: selectedCompany.value,
      });

      root.$vs.loading.close();
      location.reload();
    });

    watch(
      () => selectedCompany.value,
      (newValue) => {
        console.log(
          '🚀 ~ file: CompanySelect.vue ~ line 32 ~ setup ~ selectedCompany.value',
          newValue
        );
        root.$vs.loading();
        updateUserCompany({ company_id: newValue });
      }
    );

    return {
      companies,
      selectedCompany,
      companiesResult,
      activeUserInfo,
    };
  },
});
</script>

<style scoped></style>
